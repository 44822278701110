import React from 'react';
import './App.css';

function App()
{
  return (
    <div className="App">
      <header className="App-header">
        <img src="./assets/avatar.apng" style={{ borderRadius: "3rem" }} alt="don't worry about it" />
        <p>
          Why are you here?
        </p>
      </header>
    </div>
  );
}

export default App;
